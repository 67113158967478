<template>
  <div>
    <div
      v-if="
        (user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
        user.is_superuser
      "
    >
      <span
        v-if="Information && Information.update_date != null && infoUser"
        class="container-modification-date"
      >
        Última modificación realizada por:
        <strong>{{ infoUser.email }}</strong>
        el <strong>{{ Information.update_date | FormatToDate }}</strong> a las
        <strong>{{ Information.update_date | FormatToTime }}</strong>
      </span>
      <span
        v-else-if="Information && Information.creation_date != null && infoUser"
        class="container-modification-date"
      >
        Creado por:
        <strong>{{ infoUser.email }}</strong>
        el <strong>{{ Information.creation_date | FormatToDate }}</strong> a las
        <strong>{{ Information.creation_date | FormatToTime }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "ModificationDate",
  components: {},
  props: {
    Information: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      users: names.USERS,
      user_position: names.USER_POSITION,
    }),
    infoUser() {
      if (!this.Information) return null;
      if (this.Information.updated_by != null)
        return this.users.find((x) => x.id == this.Information.updated_by);
      else return this.users.find((x) => x.id == this.Information.created_by);
    },
  },
  methods: {},
  created() {
    if (this.Information && this.Information.updated_by != null)
      this.$store.dispatch(names.FETCH_USER, this.Information.updated_by);
  },
};
</script>

<style scoped>
.container-modification-date {
  font-size: 10pt;
}
</style>